
//TODO: clean up this mess of a package
// 1. Split out most of the search functionality into separate services
//    a. Sort + Query should be together
//    b. The query builder should be a separate component (and should support async actions)
//        i. It should provide the input bar, and needs access to the job, because it may add cases/controls
//        ii. It should expose a function to add cases/controls through a separate service, which will be re-used
//            for the toolbar button trigger of that (the default trigger will be typing cases and not having cases entered)

/*@ngInject*/ function ElasticSearchSuggest ($log, $window, $location, $http, $q,
  $routeParams, $anchorScroll, $mdDialog, _, SETTINGS) {
  // console.info("suggester loaded");
  // const defaultFuzzyTerms = [
  //   'intergenic','intronic','indel-frameshift','indel-nonframeshift','snp','del','ins',
  //   'exonic', 'ncrna', 'pathogenic', 'silent', 'replacement', 'missense', 'nonsynonymous',
  //   'coding', 'splicedonor', 'spliceaaceptor', 'utr5', 'utr3', 'single', 'uncertain',
  //   'disease', 'syndrome', 'disorder', 'receptor', 'allele', 'transcript', 'non-coding',
  //   'mrna', 'multiallelic', 'denovo', 'germline', 'somatic', 'inframe', 'nucleotide',
  //   'polymorphism', 'epilepsy', 'epileptic', 'schizophrenia', 'intellectual', 'disability',
  //   'cancer', 'carcinoma', 'seizure'
  // ];
  // let queryCanceler;
  // this.$onChanges = () => {
  //   if(this.query) {
  //     console.info('new query', this.query);
  //     // this.suggest(this.query);
  //   }
  // };

  // this.$onDestroy = () => {
  //   if(queryCanceler !== undefined) {
  //     queryCanceler.resolve();
  //   }
  // };

  // this.suggest = (newQuery) => {
  //   const parts = newQuery.split(/[\:\s+]/g);
  //   const matchParts = parts[parts.length - 1].match(/(\w+)(\S+)/);
  //   console.info('parts', parts);
  //   if(matchParts === null) {
  //     return;
  //   }

  //   const searchBody = {suggest : {}};
  //   let idx = -1;

  //   parts.forEach((trimmedWord) => {
  //     idx++;
  //     searchBody['suggest-' + ] = {
  //       suggest: {
  //         "Field Name": {
  //           text: trimmedWord,
  //           term: {
  //             min_word_length: 2,
  //             field: "_field_names"
  //           },
  //         },
  //         "Type": {
  //           text: trimmedWord,
  //           term: {
  //             min_word_length: 2,
  //             field: "type"
  //           },
  //         },
  //         "RefSeq Site Type": {
  //           text: trimmedWord,
  //           term: {
  //             prefix_length: 2,
  //             field: "refSeq.siteType"
  //           },
  //         },
  //         "RefSeq Transcript": {
  //           text: trimmedWord,
  //           term: {
  //             min_word_length: 2,
  //             field: "refSeq.name"
  //           },
  //         },
  //         "RefSeq Gene": {
  //           text: trimmedWord,
  //           term: {
  //             min_word_length: 2,
  //             field: "refSeq.name2"
  //           },
  //         },
  //         "RefSeq Exonic Allele Function": {
  //           text: trimmedWord,
  //           term: {
  //             field: "refSeq.exonicAlleleFunction"
  //           }
  //         },
  //         "Clinvar Phenotype": {
  //           text: trimmedWord,
  //           term: {
  //             field: "clinvar.phenotypeList"
  //           }
  //         },
  //         "Clinvar Clinical Significance": {
  //           text: trimmedWord,
  //           term: {
  //             field: "clinvar.clinicalSignificance"
  //           }
  //         },
  //       }
  //     };
  //   });

  //   if(queryCanceler !== undefined) {
  //     queryCanceler.resolve();
  //   }

  //   queryCanceler = $q.defer();

  //   //noRetryOnServerError to prevent stale results popping up after disconnection
  //   //Don't query model to avoid sending the entire job state (including results)
  //   $http.post(`${SETTINGS.apiEndpoint}jobs//${this.job._id}/search`, {
  //     searchBody: searchBody
  //   }, {
  //     timeout: queryCanceler.promise,
  //   }).then( (response) => {
  //     let suggestions = [];
  //     let maxScore = 0;

  //     console.info('suggestions', response.data);
  //     Object.keys(response.data.suggest).forEach( (sName) => {
  //       if(!response.data.suggest[sName][0]['options'].length) {
  //         return;
  //       }

  //       response.data.suggest[sName][0]['options'].forEach( (item, index) => {
  //         if(item.score >= 0.5) {
  //           suggestions.push([item.text, sName]);
  //         }
  //       })
  //     });

  //     this.onSuggested({inputWord: newQuery, suggestions});
  //   }, (errResponse) => {
  //     if(errResponse.status === -1) {
  //       // Aborted
  //       return;
  //     }

  //     $log.error(errResponse);
  //     // TODO: handle
  //     // $log.debug('http promise is', promise, errResponse);
  //     this.searchErr = errResponse.statusText;
  //     if(newQuery) {
  //       this.aggregations = [];
  //     }

  //     this.searching = false;
  //   });
  // };
}

angular.module('sq.jobs.results.search.suggest.component', [])

.component('sqSuggest', {
  bindings: {
    job: '<',
    query: '<',
    onSuggested: '&',
  }, // isolate scope
  template: `<span>
  <span class='suggestion-box' ng-repeat='suggestion in $ctrl.suggestions track by $index'>
    <span ng-click='$ctrl.submitQueryChange(suggestion[0])'>{{suggestion}}</span>
  </span>
  </span>`,
  controller: ElasticSearchSuggest,
});